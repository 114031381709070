import React from 'react';
import clsx from 'clsx';

import * as styles from './Entry.module.css';

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export default function DownloadEntry({ entry, downloadFunction }) {
  return (
    <div className={styles.content}>
      <div className={styles.nameAndPreviewContainer}>
        <div className={styles.name}>{entry.title}</div>
        <div className={styles.preview}>{entry.preview ? <img src={entry.preview} /> : ''}</div>
      </div>
      <div className={styles.files}>
        <ul>
          {entry.files.map((file) => {
            const className = 'rnd' + guid();
            return (
              <li key={className}>
                <a
                  href={file.url}
                  target="_blank"
                  onClick={(e) => {
                    downloadFunction(file.url, className);
                    e.preventDefault();
                  }}
                >
                  <span className={clsx('icon-download', styles.downloadIcon)}></span>
                  {file.name}
                </a>
                <a href={file.url} target="_blank" className={className} download style={{ display: 'none' }}></a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
