import * as React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { PageTitle } from '../components/Common/PageTitle';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import DownloadEntry from '../components/Download/Entry';
import Skylight from 'react-skylight';
import Button from '../components/Common/Button';
import CloseIcon from '../assets/img/close-icon.svg';

import * as styles from './downloads.module.css';

const DownloadsPage = ({ data, location }) => {
  const downloads = {};
  const that = typeof window !== 'undefined' ? window : this;

  const { siteMetadata } = data.site;
  const { title, icon, metadata, opengraph } = data.page;
  const { t } = useTranslation();

  const predownloadFunction = (name, className) => {
    that.storedFileLinkClass = className;
    that.skylight.show();
  };

  function getSkylightStyles() {
    const width = typeof window !== 'undefined' ? window.innerWidth : 0;
    const height = typeof window !== 'undefined' ? window.innerHeight : 0;

    const mobile = width > 0 && width <= 600;
    const tablet = width > 0 && width <= 900;
    const landscape = height < width;

    if (mobile && !landscape) {
      return {
        height: 'auto',
        marginTop: '0',
        width: '100%',
        left: 0,
        marginLeft: 0,
        top: 75,
        textAlign: 'center',
        minHeight: 220,
      };
    } else if (mobile && landscape) {
      return {
        height: 'auto',
        top: '50px',
        marginTop: '-25px',
        width: '60%',
        left: '50%',
        marginLeft: '-30%',
        textAlign: 'center',
        minHeight: 220,
      };
    } else if (tablet) {
      return {
        height: 'auto',
        textAlign: 'center',
      };
    }

    return {
      height: 'auto',
      width: 400,
      minHeight: 220,
      marginLeft: -200,
      textAlign: 'center',
    };
  }

  const closeButtonStyle = {
    color: 'transparent',
    right: 0,
    top: 0,
    height: 40,
    width: 40,
    borderRadius: 20,
    textAlign: 'center',
    display: 'table-cell',
    verticalAlign: 'middle',
    background: '#fff url("' + CloseIcon + '") no-repeat center center / 60% 60%',
  };

  const downloadFile = (e) => {
    document.querySelector('.' + that.storedFileLinkClass).click();
    e.preventDefault();
    that.skylight.hide();
  };

  data.downloads.edges.forEach(({ node }) => {
    const { title, image, category, files } = node;

    const data = {
      title: title,
      preview: image ? image.publicURL : null,
      files: [],
    };

    files.forEach((file) => {
      data.files.push({
        name: file.name,
        url: file.image.publicURL,
      });
    });

    downloads[category] = downloads[category] || [];
    downloads[category].push(data);
  });

  const downloadsHersteller = data.manufacturers.edges
    .map(({ node }) => {
      const { logopng, logojpg, logosvg, logoai, name } = node;

      const hasPng = !!logopng;
      const hasJpg = !!logojpg;
      const hasSvg = !!logosvg;
      const hasAi = !!logoai;

      const previewImage = hasPng ? logopng.publicURL : hasSvg ? logosvg.publicURL : hasJpg ? logojpg.publicURL : '';

      const data = {
        title: name,
        preview: previewImage ? previewImage : null,
        files: [],
      };

      if (hasSvg) {
        data.files.push({
          name: 'Logo (SVG)',
          url: logosvg.publicURL,
        });
      }
      if (hasAi) {
        data.files.push({
          name: 'Logo (AI)',
          url: logoai.publicURL,
        });
      }
      if (hasPng) {
        data.files.push({
          name: 'Logo (PNG)',
          url: logopng.publicURL,
        });
      }
      if (hasJpg) {
        data.files.push({
          name: 'Logo (JPG)',
          url: logojpg.publicURL,
        });
      }

      if (data.files.length > 0) {
        return data;
      }
      return null;
    })
    .filter(function (download) {
      return download;
    });

  return (
    <DefaultLayout>
      <div className="wrap">
        <PageTitle title={title} icon={icon} />
        {downloads['EZ Agrar'] &&
          downloads['EZ Agrar'].map((download) => {
            return <DownloadEntry entry={download} key={download.file} downloadFunction={predownloadFunction} />;
          })}
        <ScrollableAnchor id="manufacturer">
          <span></span>
        </ScrollableAnchor>
        <PageTitle title={t('downloads.manufacturer')} />
        <div className={styles.manufacturerLogos}>
          {downloadsHersteller.map((download) => {
            return <DownloadEntry entry={download} key={download.file} downloadFunction={predownloadFunction} />;
          })}
        </div>
        {typeof document !== 'undefined' && (
          <Skylight
            hideOnOverlayClicked
            ref={(ref) => {
              that.skylight = ref;
            }}
            transitionDuration={500}
            dialogStyles={getSkylightStyles()}
            closeButtonStyle={closeButtonStyle}
          >
            <br />
            <p>{t('downloads.notice')}</p>
            <Button color="blue" onClick={downloadFile} to={'/'}>
              Download
            </Button>
          </Skylight>
        )}
        <Metadata
          data={metadata}
          opengraphData={opengraph}
          defaultTitle={siteMetadata.title}
          canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
          path={location.pathname}
        />
      </div>
    </DefaultLayout>
  );
};

export default DownloadsPage;

export const DownloadsPageQuery = graphql`
  query DownloadsPageQuery($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: cockpitRegionDownloads(lang: { eq: $language }) {
      title
      icon
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    manufacturers: allCockpitHersteller(filter: { published: { eq: true }, lang: { eq: $language } }) {
      edges {
        node {
          name
          logopng {
            publicURL
          }
          logosvg {
            publicURL
          }
          logoai {
            publicURL
          }
          logojpg {
            publicURL
          }
        }
      }
    }
    downloads: allCockpitDownloads(filter: { lang: { eq: $language } }) {
      edges {
        node {
          id
          title
          category
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          files {
            name
            image {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
